var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "notice-area" }, [
        _c("div", { staticClass: "tab-nav common-type" }, [
          _c("ul", [
            _c("li", { class: { on: _vm.query.isFinish === false } }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(false)
                    },
                  },
                },
                [_vm._v("진행 중인 이벤트")]
              ),
            ]),
            _c("li", { class: { on: _vm.query.isFinish } }, [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(true)
                    },
                  },
                },
                [_vm._v("종료된 이벤트")]
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.view == false,
                expression: "view == false",
              },
            ],
          },
          [
            _c("div", { staticClass: "search-wrap" }, [
              _c("div", { staticClass: "left-col" }, [
                _c("div", { staticClass: "select-wrap" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.query.searchType,
                          expression: "query.searchType",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.query,
                            "searchType",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.codes.searchType, function (option) {
                      return _c(
                        "option",
                        { key: option.code, domProps: { value: option.code } },
                        [_vm._v(" " + _vm._s(option.label) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "search-bar" }, [
                  _c("div", { staticClass: "text-wrap" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.query.keyword,
                          expression: "query.keyword",
                        },
                      ],
                      staticClass: "search-type",
                      attrs: {
                        type: "text",
                        placeholder: "키워드를 입력하세요.",
                      },
                      domProps: { value: _vm.query.keyword },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.fetchEvents()
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.query, "keyword", $event.target.value)
                        },
                      },
                    }),
                    _c("button", { staticClass: "btn-search" }, [
                      _vm._v(" 검색 "),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "right-col event-btn-type" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn-thumb",
                    class: { active: _vm.isActiveThum },
                    on: { click: _vm.activeThumb },
                  },
                  [_c("span", [_vm._v("썸네일보기")])]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-list",
                    class: { active: _vm.isActiveList },
                    on: { click: _vm.activeList },
                  },
                  [_c("span", [_vm._v("리스트보기")])]
                ),
              ]),
            ]),
            !_vm.isEmptyView && _vm.isActiveThum
              ? _c("div", [
                  _vm.isActiveThum
                    ? _c("div", { staticClass: "thumbnail-wrap" }, [
                        _c(
                          "div",
                          { staticClass: "event-thumb-list" },
                          _vm._l(_vm.events, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "thumbnail-item",
                                staticStyle: { "margin-bottom": "30px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.eventClick(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "thumbnail-img",
                                    class: { finish: item.isFinish },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        "max-width": "100%",
                                      },
                                      attrs: { src: item.filePath },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    class: { finish: item.isFinish },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.title) +
                                        " " +
                                        _vm._s(
                                          item.commentCount != 0 &&
                                            item.allowComments
                                            ? "[" + item.commentCount + "]"
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            !_vm.isEmptyView && _vm.isActiveList
              ? _c("div", [
                  _vm.isActiveList
                    ? _c("div", { staticClass: "tb-list-type notice-type" }, [
                        _c("table", [
                          _vm._m(0),
                          _vm._m(1),
                          _c(
                            "tbody",
                            _vm._l(_vm.events, function (item) {
                              return _c(
                                "tr",
                                {
                                  key: item.id,
                                  class: {
                                    "notice-fix": item.isFixed,
                                    finish: item.isFinish,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.eventClick(item)
                                    },
                                  },
                                },
                                [
                                  item.isFixed
                                    ? _c("td", [
                                        _c("i", { staticClass: "ico-notice" }),
                                      ])
                                    : _c("td", [
                                        _vm._v(" " + _vm._s(item.id) + " "),
                                      ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      { staticClass: "reply-list-td" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "ellipsis-txt" },
                                          [
                                            _c("a", [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        item.allowComments &&
                                        item.commentCount > 0
                                          ? _c("span", [
                                              _vm._v(
                                                "[" +
                                                  _vm._s(item.commentCount) +
                                                  "]"
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("$dateFormatter")(
                                          item.createDate,
                                          "YYYY. MM. DD"
                                        )
                                      )
                                    ),
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.viewCnt))]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.isEmptyView ? _c("div", [_vm._m(2)]) : _vm._e(),
            _c("pagination", {
              attrs: {
                page: _vm.currentPage,
                records: _vm.totalRecord,
                per: _vm.pageCount,
              },
              on: { paging: _vm.pageUpdate },
            }),
          ],
          1
        ),
        _vm.view
          ? _c(
              "div",
              [
                _c("event-view", {
                  ref: "eventView",
                  attrs: { id: _vm.noticeId },
                  on: { statUpdate: _vm.statUpdate },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "100px" } }),
      _c("col", { staticStyle: { width: "531px" } }),
      _c("col", { staticStyle: { width: "180px" } }),
      _c("col", { staticStyle: { width: "90px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("순번")]),
        _c("th", [_vm._v("제목")]),
        _c("th", [_vm._v("작성일")]),
        _c("th", [_vm._v("조회수")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-events-wrap" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icon/ico-no-events.png"),
          alt: "",
          "aria-hidden": "true",
        },
      }),
      _c("p", [_vm._v("진행중인 이벤트가 없습니다")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }