<template>
  <div class="tab-contents">
    <div
      class="contact-details-view"
    >
      <div class="tb-view-type">
        <table>
          <colgroup>
            <col style="width: auto">
            <col style="width: 360px">
          </colgroup>
          <thead>
            <tr>
              <th>{{ event.title }}</th>
              <th>
                <div class="flex-event-r">
                  <strong>조회수</strong><span>{{ event.viewCnt }}</span>
                  <i class="bu-line" />
                  <strong>작성일</strong>
                  <span>{{ event.createDate | $dateFormatter('YYYY-MM-DD') }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2">
                <component
                  :is="eventContents"
                  v-if="event.eventContents !== null"
                  :event-id="event.eventId"
                  :content-id="id"
                />

                <component
                  :is="eventComponent"
                  v-else-if="event.eventPlace === 2"
                  :event-id="event.eventId"
                  :notice-id="id"
                />

                <div
                  v-else
                  ref="content"
                  v-dompurify-html="event.contents"
                  @click="handleClick"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <ul
                  v-for="item in event.attachFiles"
                  :key="item.fileId"
                  class="add-list"
                >
                  <li>
                    <a @click="downloadFile(item.fileId, item.fileName)">
                      <i class="i-file-add" />{{ item.fileName }}
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex-r">
        <button
          class="btn-list"
          @click="eventList"
        >
          목록으로
        </button>
      </div>

      <div class="tb-pn-view-type">
        <table>
          <colgroup>
            <col style="width: 180px">
            <col style="width: auto">
            <col style="width: 210px">
          </colgroup>
          <tbody>
            <tr
              v-if="event.nextEvent"
              @click="fetchEvent(event.nextEvent.id, true)"
            >
              <th>다음글</th>
              <td>
                <p>
                  <a href="#">{{ event.nextEvent.title }}</a>
                </p>
              </td>
              <td>{{ event.nextEvent.createDate | $dateFormatter('YYYY-MM-DD') }}</td>
            </tr>
            <tr
              v-if="event.prevEvent"
              @click="fetchEvent(event.prevEvent.id, true)"
            >
              <th>이전글</th>
              <td>
                <p>
                  <a href="#">{{ event.prevEvent.title }}</a>
                </p>
              </td>
              <td>{{ event.prevEvent.createDate | $dateFormatter('YYYY-MM-DD') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <comment-form
      v-if="event.allowComments"
      ref="commentForm"
      :content-type="7"
      :is-hook="event.eventPlace === 4"
      :member-only="event.eventPlace === 4 ? false : true"
      @hook="commentPreProcess"
      :comment-survey-id="event.commentSurveyId ? event.commentSurveyId : -1"
    />

    <!--
       <개인정보 동의 팝업 컴포넌트>
       댓글 입력 이벤트에서 등록 전 개인정보 입력 및 동의에 대한 전처리 수행
    -->
    <component
      :is="popupComponent"
      v-if="event.component && event.eventPlace === 4"
      ref="preProcessPopup"
      :event-id="event.eventId"
      @close="closePreProcessPopup"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { callExternalApi } from '@/core/global/global-liveinfo'
import { setStatistics } from '@/common/statistics/service'
import CommentForm from '../CommentForm.vue'

export default {
  components: {
    CommentForm,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      event: {},
      contentType: 7,
      isView: false,
      script: null,
      keepParam: null,
    }
  },
  computed: {
    ...mapGetters({
      userName: 'account/getName',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
    eventComponent() {
      return () => import(`@/components/event/${this.event.component}`)
    },
    eventContents() {
      if(this.event.eventContents !== undefined){
      return () => import(`@/components/event/${this.event.eventContents}`)
      }
      else
      return
    },
    popupComponent() {
      return () => import(`@/components/popups/${this.event.component}`)
    },
  },
  mounted() {
    this.fetchEvent(this.id)
  },
  methods: {
    eventList() {
      this.$parent.view = false
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-event-main' } }).catch(() => {})
    },
    updateViewCount(id) {
      axios.patch(`/fu/event/UpdateViewCount/${id}`).catch(() => {
      })
    },
    fetchEvent(id, innerCall = false) {
      this.updateViewCount(id)
      if (innerCall) this.$emit('statUpdate', id)

      axios.get(`/fu/event/${id}`)
        .then((rs) => {
          this.event = rs.data

          // 이벤트 뷰 페이지가 있는 경우
          if (this.event.eventContents === null && this.event.contents && this.event.contents.indexOf('<script>') > 0) {
            const text = this.event.contents.substring(this.event.contents.indexOf('script>') + 7, this.event.contents.lastIndexOf('script>') - 2)

            this.script = document.createElement('script')

            this.script.setAttribute('type', 'module')

            this.script.text = text

            setTimeout(() => {
              this.$refs.content.appendChild(this.script)
            }, 100)
          }

          setTimeout(() => {
            if (this.event.allowComments) this.$refs.commentForm.fetchComment(id, this.contentType)

            this.scrollTop()
          }, 1)
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    downloadFile(id, fileName) {
      axios.get(`/fu/attachfile/${id}`, { responseType: 'blob' })
        .then(rs => {
          const data = new Blob([rs.data])

          // const name = rs.headers["content-disposition"]
          //   .split("filename=")[1]
          //   .replace(/"/g, "")

          const reader = new FileReader()

          reader.readAsDataURL(data)

          reader.onloadend = () => {
            const content = reader.result

            callExternalApi("FileDownload", JSON.stringify({ content: content.split(',')[1], fileName }))
          }
        })
        .catch(() => {
        })
    },
    scrollTop() {
      const detailWrap = document.querySelectorAll(".sub-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: 0 })
      })
    },
    eventLink() {
      // 이벤트 참여페이지 이동 통계
      setStatistics(122, this.event.eventId)

      if (this.event.eventPlace === 3) {
        this.$router.push({ name: 'app-medical-trend-main' }).catch(() => {})
      }
    },
    handleClick() {
      const targetId = event.target.id

      switch (targetId) {
        case 'btnMove':
          this.eventLink()
          break
        case 'medical-trend-view':
          this.$router.push({ name: 'app-medical-trend-main' }).catch(() => {})
          break
        case 'drug-info-view':
          this.$router.push({ name: 'app-druginfo-main' }).catch(() => {})
          break
        case 'survey-view':
          this.$router.push({ name: 'app-survey-main' }).catch(() => {})
          break
        case 'web-seminar-view':
          this.$router.push({ name: 'app-webseminar-client' }).catch(() => {})
          break
        case 'point-mall-view':
          this.$router.push({ name: 'app-point-mall-main' }).catch(() => {})
          break
        case 'app-main':
          this.$router.push({ name: 'app-main' }).catch(() => {})
          break
        default:
          break
      }
    },
    commentPreProcess(param) {
      if (param) this.keepParam = param
      else return

      if (this.keepParam.call === 'createComment' || this.keepParam.call === 'createReply') this.$refs.preProcessPopup.call()
      else if (this.keepParam.call === 'removeComment') this.deleteEventHistory()
      else return
    },
    closePreProcessPopup(isConfirm) {
      if (isConfirm && this.keepParam) {
        if (this.keepParam.call === 'createComment') this.$refs.commentForm.createComment()
        else if (this.keepParam.call === 'createReply') this.$refs.commentForm.createReply(this.keepParam.parentCommentId, this.keepParam.replyComment)
      }
    },
    deleteEventHistory() {
      axios.delete(`/fu/event/history/${this.event.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum
        },
      })
        .then(() => {
          if (this.keepParam.call === 'removeComment') this.$refs.commentForm.deleteComment(this.keepParam.commentId)
        })
        .catch(() => {
          this.showAlertDialog('이벤트 참여 내역 삭제 처리 중 오류가 발생하였습니다.\n관리자에게 문의하여 주시기 바랍니다.')
        })
    },
  },
}
</script>

<style scoped>
table {
  border-spacing: 0;
  border-collapse: separate;
}

table thead th {
  position: sticky;
  z-index: 9;
  top: 0;
}
</style>
